<!-- <div class="menu"> -->
<div class="header header-md">
  <strong class="env">{{environmentName}}</strong>
  <picture>
    <source srcset="../../assets/logos/svg/logo-dark.svg" media="(prefers-color-scheme: dark)">
    <img src="../../assets/logos/svg/logo-light.svg">
  </picture>
  <ion-button fill="clear" class="logout" (click)="logout()">
    <ion-icon name="log-out" slot="icon-only"></ion-icon>
  </ion-button>
</div>
<div class="items" [style]="cssVariables">
  <ion-menu-toggle auto-hide="false" routerLink="/home">
    <ion-item routerLinkActive="active" routerDirection="forward" button [detail]="false">
      <ion-icon name="home-outline"></ion-icon>
      <ion-label class=" ion-padding-start">
        Inicio
      </ion-label>
      <ion-icon name="notifications" slot="end" color="warning" class='notification'
        [ngClass]="{ 'new-notification': lastNotificationDate !== undefined }"></ion-icon>
    </ion-item>
  </ion-menu-toggle>
  <ion-accordion-group [multiple]="false" [value]="currentSection" *ngIf="profile">
    <ng-container *ngFor="let section of sections">
      <ion-accordion [value]="section.name" toggleIconSlot="end" *ngIf="!section.hidded">
        <ion-item slot="header">
          <ion-icon [name]="section.icon" color="dark" class="accordion-icon"></ion-icon>
          <ion-label class="ion-padding-start"> {{section.name}} </ion-label>
        </ion-item>
        <ion-list lines="none" slot="content">
          <ng-container *ngFor="let page of section.pages">
            <ion-menu-toggle auto-hide="false" [routerLink]="page.url" *ngIf="!page.hidded">
              <ion-item routerLinkActive="active" routerDirection="forward" mode="md" button [detail]="false">
                <ion-icon [name]="page.icon" class="ion-padding-start"></ion-icon>
                <ion-label class=" ion-padding-start">
                  {{ page.title }}
                </ion-label>
              </ion-item>
            </ion-menu-toggle>
          </ng-container>
        </ion-list>
      </ion-accordion>
    </ng-container>
  </ion-accordion-group>
  <div *ngIf="!profile">
    <ion-skeleton-text [animated]="true" style="width: 100%; height: 48px;"
      *ngFor="let item of [1,2,3]"></ion-skeleton-text>
  </div>
</div>
<div class="profile">
  <div>
    <ion-grid (click)="navigate('/profile')" class="ion-no-padding">
      <ion-row class="ion-align-items-center">
        <ion-col size="auto">
          <ion-avatar>
            <img alt="Silhouette of a person's head" [src]="profile?.user?.image ?? 'assets/images/avatar.svg'" />
          </ion-avatar>
          <ion-avatar class="company">
            <img alt="Silhouette of a person's head" [src]="profile?.company?.image ?? 'assets/images/thumbnail.svg'" />
          </ion-avatar>
        </ion-col>
        <ion-col class="user">
          <ion-label *ngIf="profile">
            <h2>{{profile.user.name}}</h2>
            <h3>{{profile.user.jobTitle}}</h3>
          </ion-label>
          <ion-label *ngIf="!profile">
            <h2><ion-skeleton-text [animated]="true" style="width: 140px; height: 16px;"></ion-skeleton-text></h2>
            <h3><ion-skeleton-text style="width: 90px; height: 16px;"></ion-skeleton-text></h3>
          </ion-label>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
  <div class="version">v{{version}}</div>
</div>
<!-- </div> -->