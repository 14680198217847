import { Timestamp } from "@angular/fire/firestore";
import { ListDocument, ImagedDocument, NamedDocument, UniqueDocument } from "./BaseDocument";
import { NotificationModule } from "./Notification";

export type User = ListDocument & UserRef & {
  email: string;
  jobTitle: string;
  type: UserTypes;

  roles: any,
  permissions: any
  warehousesIds: string[],
  cashdesksIds: string[],
  notificationModules: NotificationModule[],
  lastClaimsUpdate: Timestamp | string //LEGACY: una vez actulizados todos los permisos al formato comprimido, se debe eliminar la logica del Timestamp
}

export type UserRef = UniqueDocument & NamedDocument & ImagedDocument;

export const getReference = (user: User): UserRef => {
  return {
    uid: user.uid,
    name: user.name,
    image: user.image
  }
}

export const systemUserId = "--system--";

export enum UserTypes {
  Normal = "N",
  Accountant = "A",
  Support = "S",
}

export const userTypesDetails = [
  {
    uid: UserTypes.Normal,
    name: "Normal",
  },
  {
    uid: UserTypes.Accountant,
    name: "Contador",
  },
  {
    uid: UserTypes.Support,
    name: "Soporte",
  },
]