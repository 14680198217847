import { ImagedDocument, MultiImagedDocument, UniqueDocument } from "../../../../models/BaseDocument";
import { isBase64Image } from "src/app/utilities/utililties";
import { ImageStorageService } from "../image-storage.service";
import { AuthService } from "../auth.service";

export interface Imaged<T extends (ImagedDocument | MultiImagedDocument)> {
    auth: AuthService;
    imageStorage: ImageStorageService;
}

export const saveImage = async <T extends UniqueDocument & (ImagedDocument | MultiImagedDocument)>(repository: (Imaged<T>), folders: string | string[] | undefined, document: T): Promise<T> => {
    if (document.image) {
        const profile = await repository.auth.profileOnce;

        let paths = [profile.company.uid];

        if (typeof (folders) === "string") {
            paths.push(folders);
        } else if (folders !== undefined) {
            paths = [...paths, ...folders];
        }

        //Al crear uno nuevo el Id aún no se ha asignado, antes de guardar la imagen, por lo que se le asigna uno
        if (!document.uid) {
            document.uid = crypto.randomUUID().replace(/-/g, "");
        }

        document.image = await saveIfImage(repository.imageStorage, paths, document.uid, document.image);
    }

    return document;
}

export const saveIfImage = async (storage: ImageStorageService, folders: string | string[] | undefined = "Sin_Folder", uid: string, image: string | string[] | any): Promise<string | string[] | any> => {
    if (image === undefined || image === null) {
        console.log("IMAGE No hay imagen", uid, image);
        return image;
    }

    if (typeof (image) !== "string" && !('length' in image)) {
        console.log("IMAGE No es un string o un array", uid, image);
        return image;
    }
    let path = "";

    if (typeof (folders) === "string") {
        path = folders;
    } else {
        path = folders.join("/");
    }


    if (typeof (image) === "string") {
        //Si es base 64 significa que es nueva, las cargadas de la BD se almacenan en Firebase Store
        const base64 = isBase64Image(image);

        if (base64.isBase64) {
            // Hay referencias de documentos que guardan la ruta de la imagen, 
            // si se le cae encima, el token de acceso de firebase cambia y esas referencias no cargan la imagen
            // se usa el uniqueToken para asegurar que sea distinto el nombre, asi el toke de firebase no cambia
            // y se mantiene  el historico de las imagenes.
            const uniqueToken = crypto.randomUUID().replace(/-/g, "");

            image = await storage.save(path, `${uid}_${uniqueToken}.${base64.format}`, base64.data);

            console.log("IMAGE Guardada", uid, image);
        }
        else {
            console.log("IMAGE No es base64", uid, image);
        }
    }
    else {
        for (let index = 0; index < image.length; index++) {
            //Si es base 64 significa que es nueva, las cargadas de la BD se almacenan en Firebase Store
            const base64 = isBase64Image(image[index]);

            if (base64.isBase64) {
                // Hay referencias de documentos que guardan la ruta de la imagen, 
                // si se le cae encima, el token de acceso de firebase cambia y esas referencias no cargan la imagen
                // se usa el uniqueToken para asegurar que sea distinto el nombre, asi el toke de firebase no cambia
                // y se mantiene  el historico de las imagenes.
                const uniqueToken = crypto.randomUUID().replace(/-/g, "");

                image[index] = await storage.save(path, `${uid}_${uniqueToken}.${index}.${base64.format}`, base64.data);

                console.log("IMAGE Guardada", uid, image[index]);
            }
            else{
                console.log("IMAGE No es base64 array", uid, image);
            }
        }
    }

    return image;
}