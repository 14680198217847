import { Injectable } from '@angular/core';
import { Storage, getDownloadURL, ref, uploadString, deleteObject } from '@angular/fire/storage';

@Injectable({
  providedIn: 'root'
})
export class ImageStorageService {

  constructor(
    private storage: Storage
  ) { }

  async save(folder: string, name: string, image: string): Promise<string> {
    const path = `${folder}/${name}`;

    const storageRef = ref(this.storage, path);

    await uploadString(storageRef, image, 'base64',
      {
        contentType: this.getContentType(image),
        cacheControl: 'public, max-age=31536000, immutable', //1 año = 31536000 segundos
      }
    );

    return await getDownloadURL(storageRef);
  }

  async delete(path: string): Promise<void> {
    const storageRef = ref(this.storage, path);

    await deleteObject(storageRef);
  }

  private getContentType(image: string): string | undefined{
    const match = image.match(/^data:(.+?);base64,/);

    if (match) {
      return match[1]; // Devuelve el tipo de contenido (e.g., image/png)
    }

    return undefined; // No se encontró un tipo de contenido
  }
}
