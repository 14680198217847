import { ListItem } from "../../models/ListItem";
// import { CodeDescriptionItem } from "./CodeDescriptionItem";

export enum TipoIdentificacion {
  CedulaFisica = "01",
  CedulaJuridica = "02",
  DIMEX = "03",
  NITE = "04",
  Extranjero = "05",
  NoContribuyente = "06",
}

export const TiposIdentificacion: ListItem[] = [
  { uid: TipoIdentificacion.CedulaFisica, name: "Cédula Física" },
  { uid: TipoIdentificacion.CedulaJuridica, name: "Cédula Jurídica" },
  { uid: TipoIdentificacion.DIMEX, name: "DIMEX" },
  { uid: TipoIdentificacion.NITE, name: "NITE" },
  { uid: TipoIdentificacion.Extranjero, name: "Extranjero No Domiciliado" },
  { uid: TipoIdentificacion.NoContribuyente, name: "No Contribuyente" },
]

export const identificationTypeText = (uid: TipoIdentificacion) => {
  return TiposIdentificacion.find(t => t.uid == uid)?.description;
}