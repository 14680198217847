import { Injectable } from '@angular/core';
import { ListRepositoryService } from './list-repository.service';
import { ClientBranch } from '../../../models/ClientBranch';
import { Named, byName, byNameOnce } from './interfaces/named.interface';
import { AuthService } from './auth.service';
import { Firestore } from '@angular/fire/firestore';
import { PermissionsCodes } from '../../../models/Permission';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class ClientBranchesService  extends ListRepositoryService<ClientBranch> implements Named<ClientBranch> {

  constructor(
    auth: AuthService,
    store: Firestore,
    localStorage: StorageService
  ) {
    super(auth, store, localStorage, ["clients","branches"], {
      create: [PermissionsCodes.CreateClients, PermissionsCodes.EditClients],
      read: [PermissionsCodes.ViewClients],
      update: [PermissionsCodes.CreateClients, PermissionsCodes.EditClients],
      disable: [PermissionsCodes.CreateClients, PermissionsCodes.EditClients]
    });

    this.order = undefined;
  }

  byName = (name: string, ...parentIds : string[]) => byName(this, name, undefined, ...parentIds);

  byNameOnce = (name: string, ...parentIds : string[]) => byNameOnce(this, name, ...parentIds);
}
