<ion-content [fullscreen]="true" [class.child-content]="isChild">
  <ion-grid class="ion-no-padding full">
    <ion-row>
      <ion-col>
        <div *ngIf="!isLoading">
          <ng-content select="[list]"></ng-content>
        </div>

        <div *ngIf="isLoading">
          <ng-content select="[placeholder]"></ng-content>
        </div>

        <ion-fab *ngIf="showsCreateButton">
          <ion-badge *ngIf="newBadgeValue !== undefined" [color]="newBadgeValue > 10 ? 'medium' : (newBadgeValue > 5 ? 'warning' : 'danger')">{{newBadgeValue}}</ion-badge>
          <ion-fab-button (click)="createClick()">
            <ion-icon name="add"></ion-icon>
          </ion-fab-button>
        </ion-fab>

      </ion-col>
      <ion-col [size]="isFormOpen ? 12 : 0" [sizeLg]="isFormOpen ? formSize : 0">
        <ng-content select="[form]"></ng-content>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>