import { Injectable } from '@angular/core';
import { ListRepositoryService } from './list-repository.service';
import { Dynamic } from 'models/Dynamic';
import { AuthService } from './auth.service';
import { Firestore, or, orderBy, QueryConstraint, Timestamp, Transaction, where } from '@angular/fire/firestore';
import { byName, byNameOnce, Named } from './interfaces/named.interface';
import { SourceTypes } from './repository.service';
import { firstValueFrom } from 'rxjs';
import { PermissionsCodes } from 'models/Permission';
import { Cache, expireOn10Minutes, expireOn8Hours } from '../utilities/cache.decorator';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class DynamicsService extends ListRepositoryService<Dynamic> {
  constructor(
    auth: AuthService,
    store: Firestore,
    localStorage: StorageService
  ) {
    super(auth, store, localStorage, ["dynamic-pages"], {
      create: [PermissionsCodes.CreateDynamicPages],
      read: true,
      update: [PermissionsCodes.EditDynamicPages],
      disable: [PermissionsCodes.DisableDynamicPages],
    });
  }

  override canList(): Promise<boolean> {
    return this.checkPermission([PermissionsCodes.ViewDynamicPages]);
  }

  @Cache(expireOn10Minutes)
  override byIdOnce(uid: string, transaction?: Transaction | undefined, ...parentsIds: string[]): Promise<Dynamic> {
    return super.byIdOnce(uid, transaction, ...parentsIds);
  }

  byLabels(singular: string, plural: string, source: SourceTypes = SourceTypes.Any) {
    return this.byFilter(or(where('labels.singular', '==', singular.trim()), where('labels.plural', '==', plural.trim())), source);
  }

  async byLabelsOnce(singular: string, plural: string) {
    return firstValueFrom(await this.byLabels(singular, plural, SourceTypes.Server), { defaultValue: [] as Dynamic[] }) as Promise<Dynamic[]>;
  }

  async byActiveParent(isActive: boolean, parent: string, source: SourceTypes = SourceTypes.Any) {

    const queries: QueryConstraint[] = [];

    if (parent) {
      //Las pantallas Hijas que no se muestran en el menú principal
      queries.push(where('parent.pageId', "==", parent));
      queries.push(where('parent.showsOnPrimaryMenu', '==', false));
    }

    if (isActive !== undefined) {
      queries.push(where("isActive", "==", isActive));
    }

    return this.byFilter(queries, source);
  }

  async byActiveParentOnce(isActive: boolean, parent: string) {
    return firstValueFrom(await this.byActiveParent(isActive, parent, SourceTypes.Server), { defaultValue: [] as Dynamic[] }) as Promise<Dynamic[]>;
  }
}