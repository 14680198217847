import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { Firestore, where, Transaction, or, increment, setDoc, QueryFilterConstraint, doc } from '@angular/fire/firestore';
import { Observable, combineLatest, firstValueFrom, forkJoin, map } from 'rxjs';
import { ImageStorageService } from './image-storage.service';
import { ListRepositoryService } from './list-repository.service';
import { Product, multifieldCodeSection, multifieldDescriptionSection, multifieldPageId } from '../../../models/Product';
import { splitArray } from '../utilities/utililties';
import { PermissionsCodes } from '../../../models/Permission';
import { Imaged, saveImage } from './interfaces/imaged.interface';
import { triGram } from 'models/BaseDocument';
import { FieldTypes } from 'models/MultiField';
import { SourceTypes } from './repository.service';
import { MultiFieldPageTypesService } from './multi-field-page-types.service';
import { getFields } from 'models/MultiFieldPageType';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class ProductsService extends ListRepositoryService<Product> implements Imaged<Product> {

  constructor(
    auth: AuthService,
    store: Firestore,
    localStorage: StorageService,
    public imageStorage: ImageStorageService,
    private multifieldService: MultiFieldPageTypesService,
  ) {
    super(auth, store, localStorage, ["products"], {
      create: [PermissionsCodes.CreateProducts],
      read: [PermissionsCodes.ViewProducts],
      update: [PermissionsCodes.EditProducts],
      disable: [PermissionsCodes.DisableProducts]
    });
  }

  override create = async (document: Product, transaction?: Transaction | undefined): Promise<Product> => super.create(await saveImage(this, this.collections, document), transaction)

  override update = async (document: Product, transaction?: Transaction | undefined): Promise<Product> => super.update(await saveImage(this, this.collections, document), transaction)

  protected override async triGram(product: Product): Promise<any | undefined> {
    let values: any = { ...triGram(product.code ?? '') };

    if (product.multifieldType) {
      const types = await this.multifieldService.byActiveOnce(true, undefined, undefined, multifieldPageId);

      const fileds = getFields(product.multifieldType, types);

      for (const field of fileds) {
        switch (field.type) {
          case FieldTypes.Text:
          case FieldTypes.Number:
            values = { ...values, ...triGram((product as any)[field.uid]?.toString()) };
            break;
          case FieldTypes.Select:
          case FieldTypes.ChipSelect:
            const listValue = field.list?.find(l => l.uid === (product as any)[field.uid]);

            if (listValue) {
              if (listValue.name) { }
              values = { ...values, ...triGram(listValue.name ?? ""), ...triGram(listValue.description ?? ""), ...triGram(listValue.code ?? "") };
            }
            break;
          case FieldTypes.DynamicPage:
            values = { ...values, ...triGram((product as any)[field.uid]?.name) };
            break;
          default:
            break;
        }
      }
    }

    if (product.tags && product.tags.length > 0) {
      for (const tag of product.tags) {
        values = { ...values, ...triGram(tag) };
      }
    }

    return values;
  }

  async byCode(code: string, source: SourceTypes = SourceTypes.Any) {
    return this.byFilter([where('code', '==', code.trim())], source);
  }

  async byCodeOnce(code: string): Promise<Product[]> {
    return firstValueFrom(await this.byCode(code, SourceTypes.Server), { defaultValue: [] as Product[] }) as Promise<Product[]>;
  }

  async byCodeList(codes: string[], source: SourceTypes = SourceTypes.Any): Promise<Observable<Product[]>> {
    const queries: QueryFilterConstraint[] = [];

    //La clausula 'in' en firestore tiene un limite de 30 items
    const batches = splitArray(codes, 30);

    const promises: Promise<Observable<Product[]>>[] = [];

    for (const batch of batches) {
      promises.push(this.byFilter([where('code', 'in', batch)], source));
    }

    const observables = await Promise.all(promises);

    return combineLatest(observables).pipe(map(values => (([] as Product[]).concat(...values))));
  }

  async byCodeListOnce(codes: string[]): Promise<Product[]> {
    return firstValueFrom(await this.byCodeList(codes, SourceTypes.Server), { defaultValue: [] as Product[] }) as Promise<Product[]>;
  }
}