import { Injectable } from '@angular/core';
import { Firestore, Timestamp, Transaction } from '@angular/fire/firestore';
import { AuthService } from './auth.service';
import { ListRepositoryService } from './list-repository.service';
import { MultiFieldPageType } from 'models/MultiFieldPageType';
import { Cache, expireOn8Hours } from '../utilities/cache.decorator'
import { byName, byNameOnce, Named } from './interfaces/named.interface';
import { PermissionsCodes } from 'models/Permission';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class MultiFieldPageTypesService extends ListRepositoryService<MultiFieldPageType>  implements Named<MultiFieldPageType>  {
  constructor(
    auth: AuthService,
    store: Firestore,
    localStorage: StorageService
  ) {
    super(auth, store, localStorage, ["multifields-pages", "types"], {
      create: [PermissionsCodes.CreateDynamicTypes],
      read: true,
      update: [PermissionsCodes.EditDynamicTypes],
      disable: [PermissionsCodes.DisableDynamicTypes]
    });
  }

  override canList(): Promise<boolean> {
    return this.checkPermission([PermissionsCodes.ViewDynamicTypes]);
  }

  @Cache(expireOn8Hours)
  override byActiveOnce(isActive?: boolean | undefined, take?: number | undefined, lastTimeMark?: Timestamp | undefined, ...parentsIds: string[]): Promise<MultiFieldPageType[]> {
    return super.byActiveOnce(isActive, take, lastTimeMark, ...parentsIds);
  }

  byName = (name: string, ...parentIds: string[]) => byName(this, name, undefined, ...parentIds);

  byNameOnce = (name: string, ...parentIds: string[]) => byNameOnce(this, name, ...parentIds);
}

type Cache = {
  key: string
  date: number
  result: MultiFieldPageType[],
}