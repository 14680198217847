import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { Firestore, serverTimestamp } from '@angular/fire/firestore';
import { ListRepositoryService } from './list-repository.service';
import { Device } from '../../../models/Device';
import { StorageService } from './storage.service';
import { PermissionsCodes } from 'models/Permission';
import { Consecutives } from 'models/CashDesk';
import { DocumentType } from 'models/DigitalDocument';


@Injectable({
  providedIn: 'root'
})
export class DeviceService extends ListRepositoryService<Device> {
  constructor(
    auth: AuthService,
    store: Firestore,
    localStorage: StorageService
  ) {
    super(auth, store, localStorage, ['devices']);
  }

  async current(): Promise<Device> {
    console.log('current');

    const profile = await this.auth.profileOnce;

    let device = await this.localStorage.getDevice(profile.company.uid);

    if (!device) {
      device = await this.create({
        uid: crypto.randomUUID().replace(/-/g, ""),
        number: await this.nextNumber(),
        consecutives: {
          invoice: 1,
          ticket: 1,
          creditNote: 1,
          debitNote: 1
        },
        createdBy: profile.user.uid,
        createdDate: serverTimestamp() as any,
        modifiedBy: profile.user.uid,
        modifiedDate: serverTimestamp() as any,
        isActive: true,
      });

      this.localStorage.setDevice(device, profile.company.uid);
    } else if (navigator.onLine){
      this.byIdOnce(device.uid).then(result => {
        if (!result) {
          this.create(device!);
        }
      });
    }

    return device;
  }

  //#region Document Consecutives

  async nextDocumentNumber(documentType: DocumentType): Promise<string> {
    switch (documentType) {
      case DocumentType.Invoice:
        return this.nextInvoiceNumber();
      case DocumentType.DebitNote:
      case DocumentType.DebitNoteVoid:
        return this.nextDebitNoteNumber();
      case DocumentType.CreditNote:
      case DocumentType.CreditNoteVoid:
      case DocumentType.CreditNoteRefound:
      case DocumentType.CreditNoteDiscount:
        return this.nextCreditNoteNumber();
      case DocumentType.Ticket:
        return this.nextTicketNumber();
      default:
        throw new Error(`Document type ${documentType} is not supported`);
    }
  }

  async nextInvoiceNumber(): Promise<string> {
    if (!await this.checkPermission([PermissionsCodes.CreateInvoices])) {
      throw new Error(`Forbbiden: User does not have permission to create invoices`);
    }

    return this.nextOfflineNumber(Consecutives.Invoice);
  }

  async nextTicketNumber(): Promise<string> {
    if (!await this.checkPermission([PermissionsCodes.CreateTickets])) {
      throw new Error(`Forbbiden: User does not have permission to create tickets`);
    }

    return this.nextOfflineNumber(Consecutives.Ticket);
  }

  async nextCreditNoteNumber(): Promise<string> {
    if (!await this.checkPermission([PermissionsCodes.CreateCreditNote])) {
      throw new Error(`Forbbiden: User does not have permission to create credit notes`);
    }

    return this.nextOfflineNumber(Consecutives.CreditNote);
  }

  async nextDebitNoteNumber(): Promise<string> {
    if (!await this.checkPermission([PermissionsCodes.CreateDebitNote])) {
      throw new Error(`Forbbiden: User does not have permission to create debit notes`);
    }

    return this.nextOfflineNumber(Consecutives.DebitNote);
  }

  async nextOfflineNumber(consecutiveName: Consecutives): Promise<string> {
    const profile = await this.auth.profileOnce;
    const device = await this.current();

    let number;
    let prefix;

    switch (consecutiveName) {
      case Consecutives.Invoice:
        prefix = 'FE';
        number = device.consecutives.invoice;
        device.consecutives.invoice++;
        break;
      case Consecutives.Ticket:
        prefix = 'TE';
        number = device.consecutives.ticket;
        device.consecutives.ticket++;
        break;
      case Consecutives.CreditNote:
        prefix = 'NC';
        number = device.consecutives.creditNote;
        device.consecutives.creditNote++;
        break;
      case Consecutives.DebitNote:
        prefix = 'ND';
        number = device.consecutives.debitNote;
        device.consecutives.debitNote++;
        break;
      default:
        throw new Error(`Consecutive ${consecutiveName} not found. (offline)`);
    }

    this.localStorage.setDevice(device, profile.company.uid);
    this.update(device);

    return `C${device.number!.toString().padStart(3, '0')}-${prefix}${number.toString().padStart(5, '0')}`;
  }

  //#endregion

}