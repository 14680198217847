import { Timestamp } from "@angular/fire/firestore"
import { CodigoImpuesto } from "./facturaelectronica/CodigoImpuesto"

export type Tax = {
    type: TaxType,
    mode: TaxMode,
    percentage: number,
    exempt: Exempt | null, //Solo se usa en las facturas con exoneraciones
}

export type Exempt = {
    type: string,
    number: string,
    date: Timestamp,
    name: string,
    percentage: number,
  }

export enum TaxType{
    IVA = "iva",
    ISC = "isc",
    IUC = "iuc",
    IBA = "iba",
    IBE = "ibe",
    IPT = "ipt",
    IVAE = "ivae",
    IVAU = "ivau",
    IEC = "iec",
    OTR = "otr"
  }

export enum TaxMode{
    normal = 'n',
    exclude = 'x',
    ignoreDiscount = 'd',
}

export const TaxDetails = [
    {
        uid: TaxType.IVA,
        name: "Impuesto al Valor Agregado",
        codigoImpuesto: CodigoImpuesto.IVA
    },
    {
        uid: TaxType.ISC,
        name: "Impuesto Selectivo Consumo",
        codigoImpuesto: CodigoImpuesto.ImpuestoSelectivoConsumo
    },
    {
        uid: TaxType.IUC,
        name: "Impuesto Único Combustibles",
        codigoImpuesto: CodigoImpuesto.ImpuestoUnicoCombustibles
    },
    {
        uid: TaxType.IBA,
        name: "Impuesto Específico Bebidas Alcohólicas",
        codigoImpuesto: CodigoImpuesto.ImpuestoEspecificoBebidasAlcoholicas
    },
    {
        uid: TaxType.IBE,
        name: "Impuesto Bebidas Envasadas y Jabones de Tocador",
        codigoImpuesto: CodigoImpuesto.ImpuestoBebidasEnvasadasJabonesTocador
    },
    {
        uid: TaxType.IPT,
        name: "Impuesto Productos de Tabaco",
        codigoImpuesto: CodigoImpuesto.ImpuestoProductosTabaco
    },
    {
        uid: TaxType.IVAE,
        name: "IVA (cálculo especial)",
        codigoImpuesto: CodigoImpuesto.IVAEspecial
    },
    {
        uid: TaxType.IVAU,
        name: "IVA Régimen de Bienes Usados (Factor)",
        codigoImpuesto: CodigoImpuesto.IVARegimenBienesUsados
    },
    {
        uid: TaxType.IEC,
        name: "Impuesto Específico al Cemento",
        codigoImpuesto: CodigoImpuesto.ImpuestoEspecificoCemento
    },
    {
        uid: TaxType.OTR,
        name: "Otros",
        codigoImpuesto: CodigoImpuesto.Otros
    }
]