import { Injectable } from '@angular/core';
import { ListRepositoryService } from './list-repository.service';
import { ExchangeRate } from 'models/ExchangeRate';
import { AuthService } from './auth.service';
import { Firestore, limit, orderBy, QueryConstraint, Timestamp, where } from '@angular/fire/firestore';
import { PermissionsCodes } from 'models/Permission';
import { Cache, expireOn5Minutes, expireOn8Hours } from '../utilities/cache.decorator';
import { Currencies } from 'models/Currency';
import { Functions, httpsCallable } from '@angular/fire/functions';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class ExchangeRatesService extends ListRepositoryService<ExchangeRate> {
  
  constructor(
    auth: AuthService,
    store: Firestore,
    localStorage: StorageService,
    private functions: Functions
  ) {
    super(auth, store, localStorage, ['exchange-rates'], {
      create: [PermissionsCodes.CreateExchangeRates],
      read: true,
      update: false,
      disable: false
    });
  }

  @Cache(expireOn5Minutes)
  async byCurrencies(fromCurrencyId: string, toCurrencyId: string, date: Timestamp | undefined = undefined): Promise<number> {
    if (fromCurrencyId === toCurrencyId) {
      return 1;
    }

    const queries: QueryConstraint[] = [];
    
    queries.push(where('from', '==', fromCurrencyId));
    queries.push(where('to', '==', toCurrencyId));
    queries.push(orderBy('date', 'desc'));
    queries.push(limit(1));

    if (date) {
      queries.push(where('date', '<=', date));  
    }

    const exchangeRate = await this.byFilterOnce(queries);
    
    if (exchangeRate.length > 0){
      return exchangeRate[0].rate;
    }

    const profile  = await this.auth.profileOnce;

    //Si no hay nada definido en la base de datos, se consulta el BCCR.
    if (profile.company.configuration.general.currency === Currencies.CRC) {
      return this.byCurrenciesBCCR(fromCurrencyId, toCurrencyId, date)
    } else{
      //Si la compañia esta en una moneda diferente a colones se inverte el tipo de cambio
      return 1 / await this.byCurrenciesBCCR(fromCurrencyId, toCurrencyId, date);
    }
  }

  @Cache(expireOn8Hours)
  async byCurrenciesBCCR(fromCurrencyId: string, toCurrencyId: string, date: Timestamp | undefined = undefined): Promise<number> {
    if (fromCurrencyId === toCurrencyId) {
      return 1;
    }

    let rate: number | undefined;

    const exchangeRate = httpsCallable<unknown, ExchangeRateResponse>(this.functions, 'http-common-exchangeRate');

    const response = await exchangeRate({ from: fromCurrencyId, to: toCurrencyId, date: date?.toDate()?.toISOString() });

    if (response.data.code === 200) {
      return response.data.rate;
    }
    else {
      throw response.data.message;
    }
  }
}


type ExchangeRateResponse = {
  code: number,
  message: string,
  rate: number,
}