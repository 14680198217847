import { APP_INITIALIZER, ErrorHandler, enableProdMode, importProvidersFrom } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { RouteReuseStrategy, Router, provideRouter } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { provideHttpClient } from '@angular/common/http';

import { routes } from './app/app.routes';
import { AppComponent } from './app/app.component';
import { environment } from './environments/environment';

import { getApp, initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { provideAuth, getAuth, Auth } from '@angular/fire/auth';
import { provideFirestore, getFirestore, initializeFirestore, persistentLocalCache, persistentMultipleTabManager } from '@angular/fire/firestore';
import { providePerformance, getPerformance } from '@angular/fire/performance';
import { provideAnalytics, getAnalytics, ScreenTrackingService, UserTrackingService } from '@angular/fire/analytics';
import { provideStorage, getStorage } from '@angular/fire/storage';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { provideFunctions, getFunctions } from '@angular/fire/functions'
import { addIcons } from 'ionicons'
import { LOCALE_ID } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localeCR from '@angular/common/locales/es-CR';
import { CurrentStatusPipe } from './app/pipes/current-status.pipe';
import { MultifieldDescriptionPipe } from './app/pipes/multifield-description.pipe';
import { ReferenceNumberPipe } from './app/pipes/reference-number.pipe';
import { provideServiceWorker } from '@angular/service-worker';
import { checkPermissionConsistency, setPWAThemeColor, updateDynamicRoutes } from './app/utilities/utililties';
import { GlobalErrorHandler } from './app/utilities/GlobalErrorHandler';
import { DBConfig, NgxIndexedDBModule } from 'ngx-indexed-db';
import { DynamicsService } from './app/services/dynamics.service';
import { AuthService } from './app/services/auth.service';
registerLocaleData(localeCR);

if (environment.production) {
  enableProdMode();
}

addIcons({
  'xml': './assets/icons/xml.svg',
  'xml-response': './assets/icons/xml-response.svg',
  'pdf': './assets/icons/pdf.svg',
  'xls': './assets/icons/xls.svg',
  'administration': './assets/icons/administration.svg',
  'purchasing': './assets/icons/purchasing.svg',
  'sales': './assets/icons/sales.svg',
  'store': './assets/icons/store.svg',
  'trunk': './assets/icons/truck.svg',
  'currencies': './assets/icons/currencies.svg',
  'extinguisher': './assets/icons/extinguisher.svg',
  'broom': './assets/icons/broom.svg',
  'check-payment': './assets/icons/check-payment.svg',
  'exchange-rate': './assets/icons/exchange-rate.svg',
})

const dbConfig: DBConfig = {
  name: 'mentesis',
  version: 3,
  objectStoresMeta: [{
    store: 'autosave',
    storeConfig: { keyPath: 'id', autoIncrement: false },
    storeSchema: [

    ]
  },
  {
    store: 'device',
    storeConfig: { keyPath: 'id', autoIncrement: false },
    storeSchema: [
      
    ]
  },
  {
    store: 'collectionOfflineCache',
    storeConfig: { keyPath: 'id', autoIncrement: false },
    storeSchema: [
      
    ]
  }
]
};

bootstrapApplication(AppComponent, {
  providers: [
    { provide: LOCALE_ID, useValue: 'es-CR' },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    importProvidersFrom(NgxIndexedDBModule.forRoot(dbConfig)),
    importProvidersFrom(IonicModule.forRoot({
      mode: 'md' //Fuerza el estilo 'md' en IOS
    })),
    provideRouter(routes),
    {
      provide: APP_INITIALIZER,
      useFactory: (authFirebase: Auth, auth: AuthService, dynamicsService: DynamicsService, router: Router) => {
        return async () => {   
          await authFirebase.authStateReady();
          
          if (authFirebase.currentUser){
            return updateDynamicRoutes(auth, dynamicsService, router);

          } else{
            return Promise.resolve();
          }
        }
      },
      deps: [Auth, AuthService, DynamicsService, Router],
      multi: true
    },
    provideHttpClient(),
    importProvidersFrom(provideFirebaseApp(() => initializeApp(environment.firebase))),
    importProvidersFrom(provideAuth(() => getAuth())),
    importProvidersFrom(provideFirestore(() => initializeFirestore(getApp(), {
      localCache: persistentLocalCache({
        tabManager: persistentMultipleTabManager(),
      }),
    }))),
    // importProvidersFrom(provideMessaging(() => getMessaging())),
    // importProvidersFrom(provideRemoteConfig(() => getRemoteConfig())),
    importProvidersFrom(providePerformance(() => getPerformance())),
    importProvidersFrom(provideAnalytics(() => getAnalytics())),
    importProvidersFrom(provideStorage(() => getStorage())),
    importProvidersFrom(provideFunctions(() => {
      const functions = getFunctions(undefined, "us-east1");
      // if (!environment.production) {
      //   console.warn("Using Functions Emulator");
      //   connectFunctionsEmulator(functions, 'localhost',  5001);
      // }
      return (functions);
    })),
    ScreenTrackingService,
    UserTrackingService,
    importProvidersFrom([BrowserAnimationsModule]),
    CurrentStatusPipe,
    MultifieldDescriptionPipe,
    ReferenceNumberPipe,
    provideServiceWorker('ngsw-worker.js', {
      enabled: environment.name !== 'Local',
      registrationStrategy: 'registerWhenStable:30000'
    }),
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
  ],
});

setPWAThemeColor();

checkPermissionConsistency();