import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { Firestore, QueryConstraint, Transaction, collection, doc, limit, orderBy, query, setDoc, Timestamp, startAfter, serverTimestamp, where, documentId, endBefore, QueryCompositeFilterConstraint, updateDoc, runTransaction, increment, getDocs, onSnapshot, QuerySnapshot, getDocsFromCache } from '@angular/fire/firestore';
import { Observable, catchError, combineLatest, firstValueFrom, map, of, tap } from 'rxjs';
import { splitArray, trimProperties } from '../utilities/utililties';
import { AuditableDocument, UniqueDocument } from '../../../models/BaseDocument';
import { PermissionsList, PermissionsCodes } from '../../../models/Permission';
import { traceUntilFirst } from '@angular/fire/performance';
import { serverTime } from '../utilities/serverTime';
import { removeEmpty } from 'shared/utilities';

@Injectable({
  providedIn: 'root'
})
export abstract class RepositoryService<T extends UniqueDocument & AuditableDocument> {

  protected order: { property: string, direction: 'asc' | 'desc' } | undefined = {
    property: "createdDate",
    direction: 'desc'
  }

  constructor(
    public auth: AuthService,
    protected store: Firestore,
    protected permissions: DocumentPermissions = {}
  ) { }

  //#region Path

  protected abstract paths(): Promise<string[]>;

  protected async generatePath(...parentsIds: string[]) {
    const paths = await this.paths();

    if (!paths || paths.length == 0) {
      throw new Error("There is no path defined in the repository");
    }

    if (parentsIds.length < paths.length - 1) {
      throw new Error(`All parents must have an id [${paths.join("/{id}/")}]`);
    }

    //el primer path es requerido, el resto puede ser opcional
    let path = paths[0];

    for (let index = 1; index < paths.length; index++) {

      const subPath = paths[index];
      const id = parentsIds[index - 1];

      if (!id || id.trim() === "") {
        throw new Error(`Parent Id for '${subPath}' must be provided`);
      }

      path += `/${id}/${subPath}`;
    }

    return path;
  }

  //#endregion

  //#region Permisssions

  canList(...parentsIds: string[]): Promise<boolean> {
    return this.checkPermission(this.permissions.read);
  }

  protected canRead(...parentsIds: string[]): Promise<boolean> {
    return this.checkPermission(this.permissions.read)
  }

  canCreate(...parentsIds: string[]): Promise<boolean> {
    return this.checkPermission(this.permissions.create)
  }

  canEdit(...parentsIds: string[]): Promise<boolean> {
    return this.checkPermission(this.permissions.update)
  }

  protected async checkPermission(permissions: PermissionsCodes[] | undefined | boolean): Promise<boolean> {
    //Undefine permite siempre
    if (permissions === undefined) {
      return Promise.resolve(true);
    }

    if (permissions === true) {
      return Promise.resolve(true);
    }

    if (permissions === false) {
      return Promise.resolve(false);
    }

    //Si alguno de los permisos necesarios (parametro) fue concedido para este usuario en esta compañia
    if (await this.auth.hasAccess(permissions)) {
      return true;
    }

    for (const group of PermissionsList(undefined)) {
      for (const permission of group.permissions) {
        if (permission.added.some(r => permissions.includes(r)) && await this.auth.hasAccess([permission.uid])) {
          console.log(`Allowing ${permissions} because ${permission.uid} | ${permission.name} adds it`);
          return true;
        }

        if (permission.required.some(r => permissions.includes(r)) && await this.auth.hasAccess([permission.uid])) {
          console.log(`Allowing ${permissions} because ${permission.uid} | ${permission.name} requires it`);
          return true;
        }
      }
    }

    return false;
  }

  //#endregion

  //#region Query

  public async byFilter(queries: QueryConstraint[] | QueryCompositeFilterConstraint, source: SourceTypes = SourceTypes.Any, ...parentsIds: string[]): Promise<Observable<T[]>> {
    const path = await this.generatePath(...parentsIds);

    if (!await this.canRead(...parentsIds)) {
      throw new Error(`Forbbiden: User does not have permission to read ${path}`);
    }

    console.log(path);

    const collectionRef = collection(this.store, path);

    let filtered;

    if (queries instanceof QueryCompositeFilterConstraint) {
      filtered = query(collectionRef, queries);
    } else {
      filtered = query(collectionRef, ...(queries as QueryConstraint[]));
    }

    return new Observable<T[]>(observer => {
      const cacheTimeOutMS = 1000 * 5; //5 segundos 
      let wasAServerResponse = false;

      const unsubscribe = onSnapshot(filtered, { includeMetadataChanges: true }, async (querySnapshot: QuerySnapshot) => {
        if (querySnapshot.metadata.fromCache && source === SourceTypes.Server && navigator.onLine) {

          await new Promise(resolve => setTimeout(resolve, cacheTimeOutMS));

          if (wasAServerResponse) {
            console.log('Data from cache and only server is allowed');
            return;
          }
        }

        wasAServerResponse = !querySnapshot.metadata.fromCache;

        if (!querySnapshot.metadata.fromCache) {
          const totalReadsKey = 'totalReads';
          const pathReadsKey = `pathReads_${path}`;

          let totalReads = parseInt(localStorage.getItem(totalReadsKey) ?? '0');
          let pathReads = parseInt(localStorage.getItem(pathReadsKey) ?? '0');

          totalReads += querySnapshot.docs.length;
          pathReads += querySnapshot.docs.length;

          localStorage.setItem(totalReadsKey, totalReads.toString());
          localStorage.setItem(pathReadsKey, pathReads.toString());
        }

        const items = querySnapshot.docs.map(doc => ({ uid: doc.id, ...doc.data({ serverTimestamps: 'estimate' }) } as T));
        observer.next(items);

        console.log(`${path} returned ${items.length} items ${querySnapshot.metadata.fromCache ? 'from cache' : 'from server'}`);
      }, error => {
        observer.error(error);
      });

      return { unsubscribe };
    }).pipe(
      traceUntilFirst(`firebase_${path}`),
      tap(items => {
        const totalReadsKey = 'totalReads';
        const pathReadsKey = `pathReads_${path}`;

        let totalReads = parseInt(localStorage.getItem(totalReadsKey) ?? '0');
        let pathReads = parseInt(localStorage.getItem(pathReadsKey) ?? '0');

        totalReads += items.length;
        pathReads += items.length;

        localStorage.setItem(totalReadsKey, totalReads.toString());
        localStorage.setItem(pathReadsKey, pathReads.toString());
      }),
      catchError(error => {
        console.error(`Error on query: '${path}'`, error);
        // Devolver el error para que continúe propagándose
        throw error;
      })
    );

    // return (collectionData(filtered, { idField: 'uid', serverTimestamps: 'estimate' }) as Observable<T[]>).pipe(
    // );
  }

  public async byFilterOnce(queries: QueryConstraint[] | QueryCompositeFilterConstraint, ...parentsIds: string[]): Promise<T[]> {
    return firstValueFrom(await this.byFilter(queries, SourceTypes.Server, ...parentsIds), { defaultValue: [] as T[] }) as Promise<T[]>;
  }

  async byId(uid: string, source: SourceTypes = SourceTypes.Any, ...parentsIds: string[]): Promise<Observable<T>> {
    return (await this.byIdList([uid], source, ...parentsIds)).pipe(map(items => items[0]));
  }

  async byIdOnce(uid: string, transaction: Transaction | undefined = undefined, ...parentsIds: string[]): Promise<T> {
    if (!transaction) {
      return firstValueFrom(await this.byId(uid, SourceTypes.Server, ...parentsIds), { defaultValue: undefined }) as Promise<T>;
    }
    else {
      const path = await this.generatePath(...parentsIds);

      const docRef = doc(this.store, `${path}/${uid}`);

      console.log('ById (transaction)', `${path}/${uid}`);

      const data = (await transaction.get(docRef)).data();

      if (!data) {
        return data as any;
      }
      else {
        return { ...data, uid: uid } as T;
      }
    }
  }

  async byIdList(uidList: string[], source: SourceTypes = SourceTypes.Any, ...parentsIds: string[]): Promise<Observable<T[]>> {
    //La clausula 'in' en firestore tiene un limite de 30 items
    const batches = splitArray(uidList, 30);

    const promises: Promise<Observable<T[]>>[] = [];

    for (const batch of batches) {
      const queries: QueryConstraint[] = [];

      if (batch.length === 0) {
        promises.push(Promise.resolve(of([] as T[])));
      } else {
        queries.push(where(documentId(), "in", batch));

        promises.push(this.byFilter(queries, source, ...parentsIds));
      }
    }

    const observables = await Promise.all(promises);

    return combineLatest(observables).pipe(map(values => (([] as T[]).concat(...values))));
  }

  async byIdListOnce(uidList: string[], ...parentsIds: string[]): Promise<T[]> {
    return firstValueFrom(await this.byIdList(uidList, SourceTypes.Server, ...parentsIds), { defaultValue: [] as T[] }) as Promise<T[]>;
  }

  async news(timeMark: Timestamp = Timestamp.now(), ...parentsIds: string[]) {
    const queries: QueryConstraint[] = [];

    if (this.order) {
      queries.push(orderBy(this.order.property, this.order.direction));
    }

    queries.push(endBefore(timeMark));

    return this.byFilter(queries, SourceTypes.Server, ...parentsIds);
  }

  async all(take: number | undefined = undefined, lastMark: any | undefined = undefined, source: SourceTypes = SourceTypes.Any, ...parentsIds: string[]): Promise<Observable<T[]>> {
    const queries: QueryConstraint[] = [];

    if (this.order) {
      queries.push(orderBy(this.order.property, this.order.direction));
    }

    if (take) {
      queries.push(limit(take));
    }

    if (lastMark) {
      queries.push(startAfter(lastMark))
    }


    return this.byFilter(queries, source, ...parentsIds);
  }

  async allOnce(take: number | undefined = undefined, lastTimeMark: Timestamp | undefined = undefined, ...parentsIds: string[]): Promise<T[]> {
    return firstValueFrom(await this.all(take, lastTimeMark, SourceTypes.Server, ...parentsIds), { defaultValue: [] as T[] }) as Promise<T[]>;
  }

  //#endregion

  //#region Save

  protected async completeAdditionalData(document: T, ...parentsIds: string[]): Promise<{ uid: string, data: any }> {
    //Elimina las propiedades undefine
    removeEmpty(document);

    //quita los espacios en blanco antes de guardar
    trimProperties(document);

    //Elimina el uid de los datos a salvar, para no duplicar la información
    const { uid, ...data } = document;

    return { uid, data };
  }

  async create(document: T, transaction: Transaction | undefined = undefined, ...parentsIds: string[]): Promise<T> {
    if (!await this.canCreate(...parentsIds)) {
      throw new Error(`Forbbiden: User does not have permission to create  ${await this.generatePath(...parentsIds)}`);
    }

    const profile = await this.auth.profileOnce;

    if (!document.uid) {
      document.uid = crypto.randomUUID().replace(/-/g, "");
    }

    document.createdBy = profile.user.uid;
    document.createdDate = serverTimestamp() as any;
    document.modifiedBy = profile.user.uid;
    document.modifiedDate = serverTimestamp() as any;

    const { uid, data } = await this.completeAdditionalData(document, ...parentsIds);

    const path = `${await this.generatePath(...parentsIds)}/${uid}`;

    const docRef = doc(this.store, path);

    if (!transaction) {
      console.log(`Create ${path}`);

      const result = setDoc(docRef, data);

      if (navigator.onLine) {
        //Se espera el resultado, esto tambien genera que se atrapen los errores.
        //Si esta offLine, no se hace ya que el updateDoc no resuelve hasta que tenga conexión. 
        await result;
      }
    }
    else {
      console.log(`Create (transaction) ${path}`);
      await transaction.set(docRef, data);
    }

    return document;

    // return this.save(document, transaction, ...parentsIds);
  }

  async update(document: T, transaction: Transaction | undefined = undefined, ...parentsIds: string[]): Promise<T> {
    if (!await this.canEdit(...parentsIds)) {
      throw new Error(`Forbbiden: User does not have permission to edit  ${await this.generatePath(...parentsIds)}`);
    }

    const profile = await this.auth.profileOnce;

    document.modifiedBy = profile.user.uid;
    document.modifiedDate = serverTimestamp() as any;

    const { uid, data } = await this.completeAdditionalData(document, ...parentsIds);

    const path = `${await this.generatePath(...parentsIds)}/${uid}`;

    const docRef = doc(this.store, path);

    if (!transaction) {
      console.log(`Update ${path}`);

      const result = updateDoc(docRef, data);

      if (navigator.onLine) {
        //Se espera el resultado, esto tambien genera que se atrapen los errores.
        //Si esta offLine, no se hace ya que el updateDoc no resuelve hasta que tenga conexión. 
        await result;
      }
    }
    else {
      console.log(`Update (transaction) ${path}`);
      await transaction.update(docRef, data);
    }

    return document;

    // return this.save(document, transaction, ...parentsIds);
  }

  async createOrUpdate(document: T, transaction: Transaction | undefined = undefined, ...parentIds: string[]): Promise<T> {
    //Si no trae uid es un objeto nuevo, se le crea un id único.
    if (!document.uid) {
      return this.create(document, transaction, ...parentIds);
    }
    else {
      return this.update(document, transaction, ...parentIds);
    }
  }

  async nextNumber(...parentsIds: string[]): Promise<number> {
    if (!await this.canCreate(...parentsIds)) {
      throw new Error(`Forbbiden: User does not have permission to get the next ${await this.generatePath(...parentsIds)} number`);
    }

    const documentPath = await this.generatePath(...parentsIds)
    const parts = documentPath.split("/");
    parts.splice(parts.length - 1, 0, "consecutives");
    const consecutivePath = parts.join("/");

    console.log(`Obteniendo consecutivo para ${consecutivePath}`);

    return this.nextConsecutive(consecutivePath);
  }

  protected async nextConsecutive(path: string): Promise<number> {

    console.log(`Obteniendo consecutivo para ${path}`);

    return runTransaction(this.store, async transaction => {
      const consecutiveRef = doc(this.store, path);

      const consecutive = (await transaction.get(consecutiveRef)).data();

      let number: number;

      if (!consecutive || !consecutive['number']) {
        number = 1;
        transaction.set(consecutiveRef, { number: 2 }, { merge: true });
      }
      else {
        number = consecutive['number'];
        transaction.set(consecutiveRef, { number: increment(1) }, { merge: true });
      }

      return number
    }, { maxAttempts: navigator.onLine ? 5 : 2 }); //Si esta offline solo intenta dos veces para reducir el tiempo de espera.
  }

  //#endregion

  //#region Utilities

  async serverTime(): Promise<Timestamp> {
    return Timestamp.fromDate(await serverTime());
  }

  //#endregion
}

export type DocumentPermissions = {
  create?: PermissionsCodes[] | boolean,
  read?: PermissionsCodes[] | boolean,
  update?: PermissionsCodes[] | boolean
}

export enum SourceTypes {
  Any = 'any',
  Server = 'server',
  Cache = 'cache'
}
