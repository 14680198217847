import { Injectable, Injector } from '@angular/core';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { catchError, finalize, firstValueFrom, map, of, switchMap, tap } from 'rxjs';
import { LoggerService } from './logger.service';
import { Device } from 'models/Device';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  private operationsInProgress: any = {};
  private logger: LoggerService | undefined;

  constructor(
    private dbService: NgxIndexedDBService,
    private injetor: Injector
  ) { }

  // Método para agregar un elemento a la base de datos
  async setAutoSave(key: string, data: string) {
    const operationId: string = `setAutoSave_${key}`;

    // Si hay una operación en curso para esta clave, espera a que termine
    if (this.operationsInProgress[operationId]) {
      await this.operationsInProgress[operationId];
    }

    this.operationsInProgress[operationId] = firstValueFrom(this.dbService.getByKey<any>(Storage.autosave, key).pipe(
      switchMap(value => {
        if (value) {
          return this.dbService.update<any>(Storage.autosave, { id: key, data: data });
        }

        return this.dbService.add<any>(Storage.autosave, { id: key, data: data });
      }),
      catchError((e: any) => {
        if (!this.logger){
          this.logger = this.injetor.get(LoggerService);
        }

        this.logger.error(`setAutoSave: ${key} | ${e.target.error}`);

        return of(undefined)
      }),
      finalize(() => {
        // Cuando la operación termina, la elimina del objeto de operaciones en curso
        delete this.operationsInProgress[operationId];
      })
    ));

    return this.operationsInProgress[operationId];
  }

  // Método para obtener un elemento de la base de datos por su clave
  getAutoSave(key: string) {
    return firstValueFrom(this.dbService.getByKey<any>(Storage.autosave, key).pipe(
      catchError((e: any) => {
        if (!this.logger){
          this.logger = this.injetor.get(LoggerService);
        }

        this.logger.error(`getAutoSave: ${key} | ${e.target.error}`);

        return of(undefined)
      })
    ));
  }

  // Método para eliminar un elemento de la base de datos por su clave
  removeAutoSave(key: string) {
    return firstValueFrom(this.dbService.delete<any>(Storage.autosave, key).pipe(
      catchError((e: any) => {
        if (!this.logger){
          this.logger = this.injetor.get(LoggerService);
        }

        this.logger.error(`removeAutoSave: ${key} | ${e.target.error}`);

        return of(undefined)
      })
    ));
  }

  async setDevice(device: Device, company: string) {
    const operationId: string = `device`;

    // Si hay una operación en curso para esta clave, espera a que termine
    if (this.operationsInProgress[operationId]) {
      await this.operationsInProgress[operationId];
    }

    this.operationsInProgress[operationId] = firstValueFrom(this.dbService.getByKey<any>(Storage.device, `${company}_${operationId}`).pipe(
      switchMap(value => {
        if (value) {
          return this.dbService.update<any>(Storage.device, { id: `${company}_${operationId}`, data: device });
        }

        return this.dbService.add<any>(Storage.device, { id: `${company}_${operationId}`, data: device });
      }),
      catchError((e: any) => {
        if (!this.logger){
          this.logger = this.injetor.get(LoggerService);
        }

        this.logger.error(`setDevice: ${company} ${device.uid} | ${e.target.error}`);

        return of(undefined)
      }),
      finalize(() => {
        // Cuando la operación termina, la elimina del objeto de operaciones en curso
        delete this.operationsInProgress[operationId];
      })
    ));

    return this.operationsInProgress[operationId];
  }

  getDevice(company: string) : Promise<Device | undefined>{
    const operationId: string = `device`;

    return firstValueFrom(this.dbService.getByKey<any>(Storage.device, `${company}_${operationId}`).pipe(
      map (value => value?.data),
      catchError((e: any) => {
        if (!this.logger){
          this.logger = this.injetor.get(LoggerService);
        }

        this.logger.error(`getDevice ${company} | ${e.target.error}`);

        return of(undefined)
      })
    ));
  }

  async setCollectionOfflineCache(collection: string, data: { modifiedDate: Date } ) {
    const operationId: string = `setCollectionOfflineCache_${collection}`;

    // Si hay una operación en curso para esta clave, espera a que termine
    if (this.operationsInProgress[operationId]) {
      await this.operationsInProgress[operationId];
    }

    this.operationsInProgress[operationId] = firstValueFrom(this.dbService.getByKey<any>(Storage.collectionOfflineCache, collection).pipe(
      switchMap(value => {
        if (value) {
          return this.dbService.update<any>(Storage.collectionOfflineCache, { id: collection, data: data });
        }

        return this.dbService.add<any>(Storage.collectionOfflineCache, { id: collection, data: data });
      }),
      catchError((e: any) => {
        if (!this.logger){
          this.logger = this.injetor.get(LoggerService);
        }
        
        this.logger.error(`setCollectionOfflineCache: ${collection} | ${e.target.error}`);

        return of(undefined)
      }),
      finalize(() => {
        // Cuando la operación termina, la elimina del objeto de operaciones en curso
        delete this.operationsInProgress[operationId];
      })
    ));

    return this.operationsInProgress[operationId];
  }

  getCollectionOfflineCache(collection: string) : Promise<{ modifiedDate: Date } | undefined> {
    return firstValueFrom(this.dbService.getByKey<any>(Storage.collectionOfflineCache, collection).pipe(
      map (value => value?.data),
      catchError((e: any) => {
        if (!this.logger){
          this.logger = this.injetor.get(LoggerService);
        }
        
        this.logger.error(`getCollectionOfflineCache: ${collection} | ${e.target.error}`);

        return of(undefined)
      })
    ));
  }
}

enum Storage {
  autosave = "autosave",
  device = "device",
  collectionOfflineCache = "collectionOfflineCache"
}
