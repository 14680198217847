import { BarCodeTypes } from "./BarcodeTypes";
import { ListDocument, IdentifiedDocument, ImagedDocument, NamedDocument, UniqueDocument } from "./BaseDocument";
import { CashDeskRef } from "./CashDesk";
import { Currencies } from "./Currency";
import { Status as DigitalDocumentStatus } from "./DigitalDocument";
import { ListItem } from "./ListItem";
import { Status as MaintenanceStatus } from "./Maintenance";

export type Company = ListDocument & CompanyRef & {
  accountId: string,
  configuration: Configuration;
}

export type Configuration = {
  general: {
    color: string;
    currency: string;
    exchangeRate: {
      provider: ExchangeRateProviders;
      adjust: {
        buy: number;
        sell: number;
      };
    }
  },
  barcodeScanner: {
    codeTypes: BarCodeTypes[];
    accuracy: number;
  },
  purchase: {
    isEnable: boolean,
    sendNew: mailSupplierConfiguration,
    sendCancelled: mailSupplierConfiguration,
  }
  maintenance: {
    isEnable: boolean;
    sendNew: mailClientConfiguration,
    sendCancelled: mailClientConfiguration,
    sendCompleted: mailClientConfiguration,
    createInvoice: {
      isEnable: boolean,
      onStatus: MaintenanceStatus.Completed | MaintenanceStatus.Closed,
      withStatus: DigitalDocumentStatus.Draft | DigitalDocumentStatus.New,
      cashDesk: CashDeskRef,
      economicActivity: string,
    },
    labels: {
      singular: string,
      plural: string,
      identification: string,
      icon: string
    }
  },
  stock: {
    isEnable: boolean;
    allowNegative: boolean;
    multifieldTypes: string[];
  }
  digitalInvoice: {
    isEnable: boolean;
    economicActivities: string[];
    email: string;
    sendNew: mailClientConfiguration,
  },
  cashDeskBalance: {
    isEnable: boolean;
    initialCash: number;
    tolerance: number;
  }
}

export type mailConfiguration = {
  isEnable: boolean,
  sendToUser: boolean,
  emails: string
}

export type mailClientConfiguration = mailConfiguration & {
  sendToClient: boolean,
}

export type mailSupplierConfiguration = mailConfiguration & {
  sendToSupplier: boolean,
}

export type CompanyRef = UniqueDocument & ImagedDocument & NamedDocument & IdentifiedDocument & {
  tradeName: string | null;

}

export const getReference = (company: Company): CompanyRef => {
  return {
    uid: company.uid,
    image: company.image,
    identificationType: company.identificationType,
    identification: company.identification,
    name: company.name,
    tradeName: company.tradeName,
  }
}


//#region Configuration

export const defaultEmailClientConfiguration: mailClientConfiguration = {
  isEnable: false,
  sendToClient: false,
  sendToUser: false,
  emails: ""
}

export const defaultEmailSupplierConfiguration: mailSupplierConfiguration = {
  isEnable: false,
  sendToSupplier: false,
  sendToUser: false,
  emails: ""
}

export enum ExchangeRateProviders {
  Manual = "manual",
  BCCR = "bccr",
  BCR = "bcr",
  BNCR = "bncr",
  BP = "bp",
  BAC = "bac",
  BCT = "bct",
  Cathay = "cathay",
  Citi = "citi",
  Davivienda = "davivienda",
  General = "general",
  Improsa = "improsa",
  Lafise = "lafise",
  Promerica = "promerica",
  Scotiabank = "scotiabank",
  prival = "prival",
}

export const defaultConfiguration: Configuration = {
  general: {
    color: '#5c57a6',
    currency: Currencies.CRC,
    exchangeRate: {
      provider: ExchangeRateProviders.BCCR,
      adjust: {
        buy: 0,
        sell: 0,
      },
    },
  },
  barcodeScanner: {
    codeTypes: [BarCodeTypes.EAN, BarCodeTypes.UPC],
    accuracy: 5,
  },
  purchase: {
    isEnable: false,
    sendNew: defaultEmailSupplierConfiguration,
    sendCancelled: defaultEmailSupplierConfiguration,
  },
  maintenance: {
    isEnable: false,
    sendNew: defaultEmailClientConfiguration,
    sendCancelled: defaultEmailClientConfiguration,
    sendCompleted: defaultEmailClientConfiguration,
    createInvoice: {
      isEnable: false,
      onStatus: MaintenanceStatus.Completed,
      withStatus: 'DF' as any, //HACK: por alguna razón no se puede usar DigitalDocumentStatus.Draft, ya que dice que esta undefined al cargar los modulos del webpack (posiblemenete una referencia ciclica)
      cashDesk: null as any, //Se asigna al crear la compañia con el Id de la caja creada por defecto
      economicActivity: null as any, //Se asigna al activar factura electrónica
    },
    labels: {
      singular: "Equipo",
      plural: "Equipos",
      identification: "Identificación",
      icon: "disc-outline",
    }
  },
  digitalInvoice: {
    isEnable: false,
    economicActivities: [],
    email: '',
    sendNew: defaultEmailClientConfiguration,
  },
  cashDeskBalance: {
    isEnable: false,
    initialCash: 0,
    tolerance: 0,
  },
  stock: {
    isEnable: false,
    allowNegative: false,
    multifieldTypes: ["product"]
  }
}

export const ExchangeRateProvidersDetails: ListItem[] = [
  {
    uid: ExchangeRateProviders.Manual,
    name: "Manual",
  },
  {
    uid: ExchangeRateProviders.BCCR,
    name: "Banco Central de Costa Rica",
  },
  {
    uid: ExchangeRateProviders.BCR,
    name: "Banco de Costa Rica",
  },
  {
    uid: ExchangeRateProviders.BNCR,
    name: "Banco Nacional de Costa Rica",
  },
  {
    uid: ExchangeRateProviders.BP,
    name: "Banco Popular y de Desarrollo Comunal",
  },
  {
    uid: ExchangeRateProviders.BAC,
    name: "BAC San José",
  },
  {
    uid: ExchangeRateProviders.BCT,
    name: "Banco BCT",
  },
  {
    uid: ExchangeRateProviders.Cathay,
    name: "Banco Cathay",
  },
  // Citi da problemas en el WebService del BCCR
  // {
  //   uid: ExchangeRateProviders.Citi,
  //   name: "Citibank",
  // },
  {
    uid: ExchangeRateProviders.Davivienda,
    name: "Banco Davivienda",
  },
  {
    uid: ExchangeRateProviders.General,
    name: "Banco General",
  },
  {
    uid: ExchangeRateProviders.Improsa,
    name: "Banco Improsa",
  },
  {
    uid: ExchangeRateProviders.Lafise,
    name: "Banco Lafise",
  },
  {
    uid: ExchangeRateProviders.Promerica,
    name: "Banco Promerica",
  },
  {
    uid: ExchangeRateProviders.Scotiabank,
    name: "Banco Scotiabank",
  },
  {
    uid: ExchangeRateProviders.prival,
    name: "Prival Bank",
  },
]

//#endregion
