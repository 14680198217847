import { Injectable } from '@angular/core';
import { ListRepositoryService } from './list-repository.service';
import { Notification, NotificationModule, NotificationStatus } from 'models/Notification';
import { AuthService } from './auth.service';
import { Firestore, limit, orderBy, QueryConstraint, where } from '@angular/fire/firestore';
import { PermissionsCodes } from 'models/Permission';
import { SourceTypes } from './repository.service';
import { from, Observable, of, switchMap } from 'rxjs';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService extends ListRepositoryService<Notification> {

  constructor(
    auth: AuthService,
    store: Firestore,
    localStorage: StorageService
  ) {
    super(auth, store, localStorage, ["notifications"], {
      create: false, //TODO: hacer los permisos
      read: true,
      update: true,
      disable: true
    });
  }

  //#region Save

  async read(uid: string): Promise<void> {
    await this.update({ uid: uid, status: NotificationStatus.Read } as Notification);
  }

  //#endregion

  //#region Filters

  async allAuthorized(isActive: boolean | undefined = undefined, source: SourceTypes = SourceTypes.Any): Promise<Observable<Notification[]>> {
    return this.auth.profile.pipe(switchMap(profile => {
      if (!profile) {
        return from(Promise.resolve(of([] as Notification[])));
      }

      const modules = profile.user.notificationModules;

      if (modules.length == 0) {
        return from(Promise.resolve(of([] as Notification[])));
      }

      const queries: QueryConstraint[] = [];

      queries.push(where('module', "in", modules));

      if (isActive !== undefined) {
        queries.push(where("isActive", "==", isActive));
      }

      return from(this.byFilter(queries, source));
    }), switchMap(notificaitons => {
      return notificaitons
    }));
  }

  async lastNewNotification(): Promise<Observable<Notification | undefined>> {
    return this.auth.profile.pipe(switchMap(profile => {
      if (!profile) {
        return from(Promise.resolve(of([] as Notification[])));
      }

      const modules = profile.user.notificationModules;

      if (modules.length == 0) {
        return from(Promise.resolve(of([] as Notification[])));
      }

      const queries: QueryConstraint[] = [];

      queries.push(where("status", "==", NotificationStatus.New));
      queries.push(where("isActive", "==", true));
      queries.push(where('module', "in", modules));
      queries.push(orderBy("createdDate", "desc"));
      queries.push(limit(1));

      return from(this.byFilter(queries, SourceTypes.Server));
    }), switchMap(notificaitons => {
      return notificaitons.pipe(switchMap(notifications => {
        return notifications.length > 0 ? of(notifications[0]) : of(undefined);
      }));
    }));
  }

  //#endregion
}
