import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { Firestore, Transaction, where } from '@angular/fire/firestore';
import { triGram } from 'models/BaseDocument';
import { ListRepositoryService } from './list-repository.service';
import { ImageStorageService } from './image-storage.service';
import { Client } from '../../../models/Client';
import { Observable, firstValueFrom } from 'rxjs';
import { PermissionsCodes } from '../../../models/Permission';
import { Identified, byIdentification, byIdentificationNumber, byIdentificationNumberOnce, byIdentificationOnce } from './interfaces/identified.interface';
import { TipoIdentificacion } from '../../../models/facturaelectronica/TipoIdentificacion';
import { Imaged, saveImage } from './interfaces/imaged.interface';
import { ObligadoTributarioResponse } from 'models/ObligadoTributarioResponse';
import { HttpClient } from '@angular/common/http';
import { StorageService } from './storage.service';


@Injectable({
  providedIn: 'root'
})
export class ClientsService extends ListRepositoryService<Client> implements Identified<Client>, Imaged<Client> {

  constructor(
    auth: AuthService,
    store: Firestore,
    localStorage: StorageService,
    public imageStorage: ImageStorageService,
    private httpClient: HttpClient
  ) {
    super(auth, store, localStorage, ["clients"], {
      create: [PermissionsCodes.CreateClients],
      read: [PermissionsCodes.ViewClients],
      update: [PermissionsCodes.EditClients],
      disable: [PermissionsCodes.DisableClients]
    });

    this.order = {
      property: 'name',
      direction: 'asc'
    }
  }

  protected override triGram(client: Client): any | undefined {
    return { ...triGram(client.name), ...triGram(client.tradeName ?? "") }
  }

  byIdentification = (identificationType: TipoIdentificacion, identification: string) => byIdentification(this, identificationType, identification)

  byIdentificationOnce = (identificationType: TipoIdentificacion, identification: string) => byIdentificationOnce(this, identificationType, identification)

  byIdentificationNumber = (identification: string) => byIdentificationNumber(this, identification)

  byIdentificationNumberOnce = (identification: string) => byIdentificationNumberOnce(this, identification)

  override create = async (document: Client, transaction?: Transaction | undefined): Promise<Client> => super.create(await saveImage(this, this.collections, document), transaction)

  override update = async (document: Client, transaction?: Transaction | undefined): Promise<Client> => super.update(await saveImage(this, this.collections, document), transaction)

  //#region Hacienda

  async getHaciendaData(id: string): Promise<ObligadoTributarioResponse> {
    return firstValueFrom(this.httpClient.get<ObligadoTributarioResponse>(`https://api.hacienda.go.cr/fe/ae?identificacion=${id}`));
  }

  //#endregion
}
