import { Injectable } from '@angular/core';
import { Currencies, Currency } from 'models/Currency';
import { AuthService } from './auth.service';
import { Firestore, Timestamp } from '@angular/fire/firestore';
import { HttpClient } from '@angular/common/http';
import { TipoCambioResponse } from 'models/TipoCambioResponse';
import { firstValueFrom } from 'rxjs'
import { Cache, expireOn8Hours } from '../utilities/cache.decorator';
import { ListRepositoryService } from './list-repository.service';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class CurrenciesService extends ListRepositoryService<Currency> {
  constructor(
    auth: AuthService,
    store: Firestore,
    localStorage: StorageService,
    private httpClient: HttpClient
  ) {
    super(auth, store, localStorage, ['currencies'], {
      create: false,
      read: true,
      update: false,
      disable: false
    });

    this.order = undefined;

    //Se llama para refrescar el cache desde el inicio.
    this.byActiveOnce(true);
  }

  protected override paths(): Promise<string[]> {
    return Promise.resolve(this.collections);
  }

  @Cache(expireOn8Hours)
  override async byActiveOnce(isActive: boolean | undefined = undefined, take: number | undefined = undefined, lastTimeMark: Timestamp | undefined = undefined, ...parentsIds: string[]): Promise<Currency[]> {
    const currencies = await super.byActiveOnce(isActive, take, lastTimeMark, ...parentsIds);

    return currencies.sort((a, b) => a.name.localeCompare(b.name));
  }
}