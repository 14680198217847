import { ListDocument, IdentifiedDocument } from "../../../../models/BaseDocument";
import { where } from "@angular/fire/firestore";
import { Observable, firstValueFrom } from "rxjs";
import { RepositoryService, SourceTypes } from "../repository.service";
import { TipoIdentificacion } from "models/facturaelectronica/TipoIdentificacion";

export interface Identified<T extends ListDocument & IdentifiedDocument> {
    byIdentification(identificationType: TipoIdentificacion, identification: string): Promise<Observable<T[]>>;

    byIdentificationOnce(identificationType: TipoIdentificacion, identification: string): Promise<T[]>;

    byIdentificationNumber(identification: string): Promise<Observable<T[]>>;

    byIdentificationNumberOnce(identification: string): Promise<T[]>;
}

export const byIdentification = <T extends ListDocument & IdentifiedDocument>(repository: RepositoryService<T>, identificationType: TipoIdentificacion, identification: string, source: SourceTypes = SourceTypes.Any): Promise<Observable<T[]>> => {
    return repository.byFilter([where(`identificationType`, '==', identificationType), where(`identification`, '==', identification)], source)
}

export const byIdentificationOnce = async <T extends ListDocument & IdentifiedDocument>(repository: RepositoryService<T>, identificationType: TipoIdentificacion, identification: string): Promise<T[]> => {
    return firstValueFrom(await byIdentification(repository, identificationType, identification, SourceTypes.Server), { defaultValue: [] as T[] }) as Promise<T[]>;
}

export const byIdentificationNumber = async <T extends ListDocument & IdentifiedDocument>(repository: RepositoryService<T>, identification: string, source: SourceTypes = SourceTypes.Any): Promise<Observable<T[]>> => {
    return repository.byFilter([where("identification", "==", identification)], source)
}

export const byIdentificationNumberOnce = async <T extends ListDocument & IdentifiedDocument>(repository: RepositoryService<T>, identification: string): Promise<T[]> => {
    return firstValueFrom(await byIdentificationNumber(repository, identification, SourceTypes.Server), { defaultValue: [] as T[] }) as Promise<T[]>;
}