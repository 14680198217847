//El descuento puede ser por monto o por porcentaje

export type Discount = PercentageDiscount | AmountDiscount;

export type PercentageDiscount = {
    type: DiscountType.Percentage,
    name: string,
    percentage: number,
}

export type AmountDiscount = {
    type: DiscountType.Amount,
    name: string,
    amount: number,
}

export enum DiscountType {
    Percentage = 'p',
    Amount = 'a'
}