import { Timestamp } from "@angular/fire/firestore";
import { TipoIdentificacion } from "./facturaelectronica/TipoIdentificacion";
import { UserRef } from "./User";

//#region Base

export type UniqueDocument =
  {
    uid: string;
  }

export type AuditableDocument =
  {
    createdBy: string;
    createdDate: Timestamp;
    modifiedBy: string;
    modifiedDate: Timestamp;
  }

export type BaseDocument = UniqueDocument & AuditableDocument;

//#endregion

//#region Catalogos/Listas

export type FullTextSearchDocument =
  {
    searchText?: string;
  }

export type DeactivableDocument = {
  isActive: boolean
}

export type ListDocument = BaseDocument & FullTextSearchDocument & DeactivableDocument;

//#endregion

//#region Transacionales

export type DateableDocument = {
  date: Timestamp
}

export type NumerableDocument = {
  number: number | string | null //Los borradores no tien número
}

export type AssignableDocument = {
  user: UserRef
}

export type TransactionalDocument = BaseDocument & NumerableDocument & DateableDocument & AssignableDocument;

//#endregion

//#region Resumenes

export type SummarizableDocument = BaseDocument & {
  date: Timestamp;
  period: SummarizablePeriod;
}

export enum SummarizablePeriod {
  Day = 'day',
  Month = 'month',
  Year = 'year'
}

//#endregion

//#region Communes

export type NamedDocument = {
  name: string;
}

export type ImagedDocument = {
  image: string | null;
}

export type MultiImagedDocument = {
  image: string[];
}

export type IdentifiedDocument = {
  identificationType: TipoIdentificacion;
  identification: string;
}

export type MultiFieldTypedDocument = {
  multifieldType: string;
  [key: string]: any;
}

//#endregion

export const triGram = (txt: string) => {
  const map: any = {};
  const s1 = normalizeText(txt || '');
  const n = 3;
  for (let k = 0; k <= s1.length - n; k++) {
    let key = s1.substring(k, k + n);

    //Restricciondes de los key de Firebase
    //Keys are UTF-8 encoded and can't contain new lines or any of the following characters: . $ # [ ] / or any ASCII control characters (0x00 - 0x1F and 0x7F)
    //https://firebase.google.com/docs/database/usage/limits#data_tree

    key = key.replace(/\./gi, '%46');
    key = key.replace(/\$/gi, '%36');
    key = key.replace(/\#/gi, '%35');
    key = key.replace(/\[/gi, '%91');
    key = key.replace(/\]/gi, '%93');
    key = key.replace(/\//gi, '%47');

    map[key] = true;
  }
  return map;
};


/**
 * Normaliza un texto eliminando diacríticos y convirtiéndolo a minúsculas.
 * @param txt El texto a normalizar.
 * @returns El texto normalizado.
 */
export const normalizeText = (txt: string) => {
  //TODO: remeplaza ñ por n, evaluar si es necesario mantenerlas invaribles
  return txt.toString().normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
}

export const removePreposition = (list: string[]) => {
  //las de menos de tres letras no se incluyen, como 'Y' 'EN' 'DE' 'A' 'O' 'SI'
  const stopWords = ['ante', 'bajo', 'con', 'cabe', 'contra', 'desde', 'entre',
    'hacia', 'hasta', 'mediante', 'para', 'por', 'según', 'segun', 'sin', 'tras', 'vía', 'via',
    'sobre', 'los', 'las', 'una', 'unos', 'unas', 'este', 'esta', 'estos', 'estas', 'ese', 'esa', 'esos', 'esas', 'aquí', 'ahí', 'allí',
    'como', 'pero', 'porque', 'cuando', 'donde', 'mientras', 'quien', 'quienes', 'cual', 'cuales', 'cuyo', 'cuya', 'cuyos', 'cuyas', 'del'
  ];

  return list.filter(item => item.length > 2 && !stopWords.includes(item));
}