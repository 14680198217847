import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ListDocument, NamedDocument, UniqueDocument } from "./BaseDocument";
import { MultiField } from "./MultiField";

export type MultiFieldPageType = ListDocument & NamedDocument & {
    sections: (UniqueDocument & NamedDocument & {
        fields: MultiField[]
    })[]
};

export const getFields = (multifieldType: string, types: MultiFieldPageType[]) => {
    const type = types.find(t => t.uid === multifieldType);

    if (!type) {
        console.warn(`The MultifieldType '${multifieldType}' was not found`);
        return [];
    }

    const fields = type?.sections
        .reduce((fieldList, section) => fieldList.concat(section.fields.filter(f => f.isActive).sort((a, b) => (a.order - b.order))), [] as MultiField[])
        .filter(f => f.isActive) ?? [];

    return fields;
}

export const areAllSameType = (items: { multifieldType: string }[]) => {
    // Verificar si el array está vacío
    if (items.length === 0) {
        return true;
    }

    // Obtener el tipo del primer elemento
    const type = items[0].multifieldType;

    // Verificar si todos los elementos tienen el mismo tipo
    for (const item of items) {
        if (item.multifieldType !== type) {
            return false;
        }
    }

    return true;
}

export const onMultifieldTypeChanged = ([prev, current]: [string | null, string | null], types: MultiFieldPageType[], data: FormGroup) => {
    if (prev === current) {
      return;
    }

    const previousType = types.find(t => t.uid === prev);
    const currentType = types.find(t => t.uid === current);

    const temp = data.value;

    if (previousType) {
      const fields = previousType.sections
        .reduce((fieldList, section) => fieldList.concat(section.fields.filter(f => f.isActive)), [] as MultiField[]) ?? [];

      for (const field of fields) {
        data.removeControl(field.uid, { emitEvent: false })
      }
    }

    if (currentType) {
      const fields = currentType.sections
        .reduce((fieldList, section) => fieldList.concat(section.fields.filter(f => f.isActive)), [] as MultiField[]) ?? [];

      for (const field of fields) {
        data.addControl(field.uid, new FormControl(temp[field.uid] ?? null, field.isRequired ? Validators.required : undefined), { emitEvent: false })
      }
    }
  }