import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IonicModule } from '@ionic/angular';

@Component({
  selector: 'app-list-body',
  templateUrl: './list-body.component.html',
  styleUrls: ['./list-body.component.scss'],
  standalone: true,
  imports: [CommonModule, IonicModule]
})
export class ListBodyComponent implements OnInit {
  @Input() isFormOpen: boolean = false;
  @Input() formSize: number = 6;
  @Input() showsCreateButton: boolean = true;
  @Input() isLoading: boolean = true;
  @Input() isChild: boolean = false;
  @Input() newBadgeValue: number | undefined;

  @Output() isFormOpenChange = new EventEmitter<boolean>();
  @Output() create = new EventEmitter();

  constructor() { }

  ngOnInit() { }

  createClick() {
    this.create.emit();
  }
}
