import { ListDocument, NamedDocument } from "../../../../models/BaseDocument";
import { where } from "@angular/fire/firestore";
import { Observable, firstValueFrom } from "rxjs";
import { RepositoryService, SourceTypes } from "../repository.service";

export interface Named<T extends ListDocument & NamedDocument>{
    byName(name: string) : Promise<Observable<T[]>>;

    byNameOnce(name: string) : Promise<T[]>;
}

export const byName = <T extends ListDocument & NamedDocument>(repository: RepositoryService<T>, name: string, source: SourceTypes = SourceTypes.Any, ...parentIds : string[]) : Promise<Observable<T[]>> => {
    return repository.byFilter([where(`name`, '==', name)], source, ...parentIds)
} 

export const byNameOnce = async <T extends ListDocument & NamedDocument>(repository: RepositoryService<T>, name: string, ...parentIds : string[]) : Promise<T[]> => {
    return firstValueFrom(await byName(repository, name, SourceTypes.Server, ...parentIds), { defaultValue: [] as T[] }) as Promise<T[]>;
} 